import { Camera, File } from "lucide-react";

export function UploadButtonFile({ title, inputChangeHandler, inputRef }) {
  return (
    <div
      className="border border-dotted border-green-light p-2 rounded-lg hover:cursor-pointer place-items-center flex"
      onClick={() => inputRef.current.click()}
    >
      <div className="flex flex-row items-center gap-2 justify-center w-full">
        <File className="w-8 h-8 text-green-light" />
        <p className="text-center">{title}</p>
        <input type="file" hidden ref={inputRef} accept=".pdf, .docx" onChange={inputChangeHandler} />
      </div>
    </div>
  );
}
