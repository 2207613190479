import env from "@/config/env";
import { create } from "zustand";

export interface IUiStore {
  title: string;
  favicon: string;
  preferredLanguage: string;
  showMenu: boolean;
}

export interface IUiSetter {
  updateState: (update: Partial<IUiStore>) => void;
  setShowMenu: (value: boolean) => void;
}

const { resellerFavicon, lang } = JSON.parse(
  localStorage.getItem(env.COOKIE_NAME) ?? "{}",
);

const initalState = {
  title: "",
  favicon: resellerFavicon,
  preferredLanguage: lang,
  showMenu: false,
};

const UiStore = create<IUiStore & IUiSetter>((set) => ({
  ...initalState,
  updateState: (update) => set(() => ({ ...update })),
  setShowMenu: (showMenu) => set(() => ({ showMenu })),
}));

export default UiStore;
