export const StepTwo = ({ form }) => {
  return (
    <div className="space-y-8 w-3/4">
      <div className="bg-green-light px-4 py-4 rounded-lg uppercase">
        <p>Renseignez le(s) type(s) de travaux</p>
      </div>

      <div className="space-y-4">
        <p className="text-xl">Choisissez jusqu'à 3 catégories de travaux</p>
        <p>Pour que les professionnels puissent répondre à votre demande de devis, choisissez la ou les catégories de vos travaux</p>
      </div>

      <p className="mt-12 border px-8 py-6 border-green-light rounded-xl flex flex-row gap-8 flew-wrap">
        <p>Carrelage</p>
        <p>Rénovation énergétique</p>
        <p>Maçonnerie</p>
      </p>
    </div>
  );
};
