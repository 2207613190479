"use client";

import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { Button } from "@/components/ui/button";
import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel } from "@/components/ui/form";
import { Switch } from "@/components/ui/switch";
import { Check, X } from "lucide-react";
import { Input } from "@/components/ui/input";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { useNavigate } from "react-router-dom";

const FormSchema = z.object({
  invitation_status: z.string(),
  email: z.string(),
});

export function FormInvitation() {
  const navigate = useNavigate();
  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
  });

  function onSubmit(data: z.infer<typeof FormSchema>) {
    if (data.invitation_status === "accepted") {
      navigate("/creation-dossier/step-form");
    }
  }

  const acceptedInvitation = form.watch("invitation_status") === "accepted";

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="w-full p-4 flex flex-col justify-between gap-8 h-[calc(100%-16px)]">
        <div>
          <div className="space-y-4">
            <FormField
              control={form.control}
              name="invitation_status"
              render={({ field }) => (
                <FormItem className="space-y-3">
                  <FormControl>
                    <RadioGroup onValueChange={field.onChange} defaultValue={field.value} className="flex flex-row gap-8">
                      <FormItem className="flex items-center space-x-3 space-y-0">
                        <FormControl>
                          <RadioGroupItem value="new" />
                        </FormControl>
                        <FormLabel className="font-normal">Nouvelle invitation</FormLabel>
                      </FormItem>
                      <FormItem className="flex items-center space-x-3 space-y-0">
                        <FormControl>
                          <RadioGroupItem value="accepted" />
                        </FormControl>
                        <FormLabel className="font-normal">Invitation acceptée</FormLabel>
                      </FormItem>
                    </RadioGroup>
                  </FormControl>
                </FormItem>
              )}
            />

            <div className="space-y-2">
              <span className="text-sm">Renseignez sont mail d’adhérent</span>
              <FormField
                control={form.control}
                name={"email"}
                render={({ field }) => (
                  <FormItem className="flex w-full flex-col items-start">
                    <FormControl>
                      <Input className="py-6 border-green-light" placeholder="Email" {...field} />
                    </FormControl>
                  </FormItem>
                )}
              />
            </div>
            {acceptedInvitation && (
              <div>
                <div className="flex flex-row gap-8 flex-wrap">
                  <div>
                    <img src="/image/profile.jpg" alt="logo" width={200} height={200} className="rounded-2xl" />
                  </div>
                  <div>
                    <h3 className="font-bold">CARLOS</h3>
                    <h5>Peitrin</h5>
                    <p>carlospetr@gmail.com</p>
                    <p>+33 45 678 3422</p>
                    <p>France, Lyon</p>
                    <p>Siret</p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <Button className="btn-green" type="submit">
          {acceptedInvitation ? "Valider" : "Envoyer"}
        </Button>
      </form>
    </Form>
  );
}
