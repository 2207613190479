import { X } from "lucide-react";

export const MediaPhoto = ({ url, name, handleRemove, index }) => {
  return (
    <div className="w-[150px] h-[150px] bg-white rounded-lg relative">
      <X className="absolute -top-2 -right-2 cursor-pointer bg-red-600 p-1 rounded-full" color="white" onClick={() => handleRemove(index)} />
      <img src={url} alt={name} height={98} width={98} className="object-contain w-full h-full" />
    </div>
  );
};
