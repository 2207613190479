import { Button } from "@/components/ui/button";

export const BlocGift = ({ gift }) => {
  return (
    <div className="w-[350px] relative p-8 bg-gradient-to-r from-[#FFDE59] to-[#FF914D] rounded-2xl h-[500px]">
      <div className="absolute -top-24 left-20 bg-green-light rounded-full w-[200px] h-[200px] flex justify-center flex-col items-center">
        <p className="text-4xl font-bold">{gift.price}€</p>
        <div dangerouslySetInnerHTML={{ __html: gift?.descriptionPrice }} />
      </div>
      {gift.type === "year" && (
        <div className="absolute top-32 -rotate-90 -left-20 bg-green-light text-white">
          <p className="px-6 py-3 font-semibold">Recommandé</p>
        </div>
      )}
      <div className="flex justify-between flex-col h-full items-center">
        <div className="mt-24 space-y-6">
          <p className="text-xl text-center font-bold">{gift.name}</p>
          <p className="text-center">{gift.description}</p>
          <p className="text-center">{gift?.descriptionEco}</p>
        </div>
        <div className="mt-4">
          <Button className="btn-green">Souscrire</Button>
        </div>
      </div>
    </div>
  );
};
