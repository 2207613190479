import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { InfoPub } from "@/modules/pubs/components/info-pub";
export function EspaceTarifs() {
  return (
    <div className="container lg:px-0 px-8 mx-auto mt-8 ">
      <Tabs defaultValue="information">
        <TabsList className="flex gap-4 justify-start flex-wrap h-auto border-b-2 pb-4">
          <TabsTrigger value="adhesion">Adhésions</TabsTrigger>
          <TabsTrigger value="dossier">Dossier client</TabsTrigger>
          <TabsTrigger value="pub">Mon espace pub</TabsTrigger>
        </TabsList>
        <TabsContent value="adhesion"></TabsContent>
        <TabsContent value="dossier"></TabsContent>
        <TabsContent value="pub">
          <InfoPub />
        </TabsContent>
      </Tabs>
    </div>
  );
}
