import { Button } from "@/components/ui/button";
import { useNavigate } from "react-router-dom";

export const HomePage = () => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/avantages-pro");
  };

  return (
    <div className="container mx-auto mt-8">
      <div className="lg:max-w-[50%] lg:p-0 px-8  space-y-8">
        <img src="/image/big-logo.png" alt="logo" width={210} height={210} />
        <div className="space-y-4">
          <h4>
            L’application 100% gratuite pour les consommateurs qui vas vous
            faire aimer les travaux
          </h4>
          <h2 className="font-semi-bold">
            Avec l’appli facilitez et cadrez simplement vos travaux
          </h2>
          <h4>
            Identifier les bons professionnels : découvrez ceux prêts à
            effectuer les actions suggérées par celle-ci !
          </h4>
          <h4>
            Gérez chaque étape de votre projet : a distance et en temps réel !
          </h4>
        </div>
        <Button className="bg-green-light" onClick={handleClick}>
          Les Avantages conso & pro
        </Button>
      </div>
    </div>
  );
};
