import { CreateDossierContact } from "../create-dossier-contact";

export const CreationDossier = () => {
  return (
    <div className="container lg:px-0 px-8 mx-auto mt-16">
      <hr className="border-green-light"></hr>
      <CreateDossierContact />
    </div>
  );
};
