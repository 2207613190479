import { Suspense } from "react";
import { RouterProvider } from "react-router-dom";
import "./i18n";
import { listRoutes } from "./routes";

function App() {
  return (
    <Suspense fallback={<></>}>
      <RouterProvider router={listRoutes} />
    </Suspense>
  );
}

export default App;
