import { Label } from "@/components/ui/label";
import { Textarea } from "@/components/ui/textarea";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import SwitcherCustom from "@/components/ui/switch-button";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";
import { isValidPhoneNumber } from "react-phone-number-input";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { PhoneInput } from "@/components/ui/phone-input";
import { PasswordInput } from "@/components/ui/input-password";
import { Checkbox } from "@/components/ui/checkbox";

const FormSchema = z.object({
  firstname: z.string().min(2, {
    message: "Champ obligatoire.",
  }),
  lastname: z.string().min(2, {
    message: "Champ obligatoire.",
  }),
  email: z.string().min(2, {
    message: "Champ obligatoire.",
  }),
  phoneNumber: z
    .string()
    .refine(isValidPhoneNumber, { message: "Numero de téléphone invalide." }),
  country: z.string(),
  city: z.string().optional(),
  siret: z.string().optional(),
  company: z.string().optional(),
  zip: z.string().optional(),
});

export const EditedProfile = ({ handleEdit }) => {
  const { t } = useTranslation();
  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      firstname: "",
      lastname: "",
      email: "",
      phoneNumber: "",
      country: "",
      siret: "",
      company: "",
      zip: "",
      city: "",
    },
  });

  function onSubmit(data: z.infer<typeof FormSchema>) {
    handleEdit(false);
  }

  return (
    <div className="mx-auto mt-16">
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
          <div className="flex flex-row gap-12">
            <div className="w-1/3">
              <div className="space-y-8">
                <div>
                  <img
                    src="/image/profile.jpg"
                    alt="logo"
                    width={210}
                    height={210}
                    className="rounded-2xl"
                  />
                </div>
                <div className="grid w-full gap-8">
                  <Label htmlFor="message">Description</Label>
                  <FormField
                    control={form.control}
                    name={"lastname"}
                    render={({ field }) => (
                      <FormItem className="flex w-full flex-col items-start">
                        <FormControl>
                          <Textarea
                            placeholder="Type your message here."
                            id="message"
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
              </div>
            </div>
            <div className="w-2/3">
              <h3 className="font-bold">Mes informations</h3>
              <div className="mt-8">
                <div className="grid grid-cols-2 gap-6">
                  <FormField
                    control={form.control}
                    name={"lastname"}
                    render={({ field }) => (
                      <FormItem className="flex w-full flex-col items-start">
                        <FormControl>
                          <Input
                            className="py-6 border-green-light"
                            placeholder={t("FORM.NAME")}
                            {...field}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name={"country"}
                    render={({ field }) => (
                      <FormItem className="flex w-full flex-col items-start">
                        <FormControl>
                          <Input
                            className="py-6 border-green-light"
                            placeholder={t("FORM.COUNTRY")}
                            {...field}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name={"firstname"}
                    render={({ field }) => (
                      <FormItem className="flex w-full flex-col items-start">
                        <FormControl>
                          <Input
                            className="py-6 border-green-light"
                            placeholder={t("FORM.FIRSTNAME")}
                            {...field}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name={"city"}
                    render={({ field }) => (
                      <FormItem className="flex w-full flex-col items-start">
                        <FormControl>
                          <Input
                            className="py-6 border-green-light"
                            placeholder={t("FORM.CITY")}
                            {...field}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />

                  <FormField
                    control={form.control}
                    name="phoneNumber"
                    render={({ field }) => (
                      <FormItem className="flex w-full flex-col items-start">
                        <FormControl className="w-full">
                          <PhoneInput
                            defaultCountry="FR"
                            placeholder="Téléphone"
                            {...field}
                          />
                        </FormControl>
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name={"zip"}
                    render={({ field }) => (
                      <FormItem className="flex w-full flex-col items-start">
                        <FormControl>
                          <Input
                            className="py-6 border-green-light"
                            placeholder={t("FORM.ZIP")}
                            {...field}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name={"company"}
                    render={({ field }) => (
                      <FormItem className="flex w-full flex-col items-start">
                        <FormControl>
                          <Input
                            className="py-6 border-green-light"
                            placeholder={t("FORM.COMPANY")}
                            {...field}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />

                  <FormField
                    control={form.control}
                    name={"email"}
                    render={({ field }) => (
                      <FormItem className="flex w-full flex-col items-start">
                        <FormControl>
                          <Input
                            className="py-6 border-green-light"
                            placeholder={t("FORM.EMAIL")}
                            {...field}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name={"siret"}
                    render={({ field }) => (
                      <FormItem className="flex w-full flex-col items-start">
                        <FormControl>
                          <Input
                            className="py-6 border-green-light"
                            placeholder={t("FORM.SIRET")}
                            {...field}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
                <div className="flex justify-end">
                  <Button className="btn-green mt-4">valider</Button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </Form>
    </div>
  );
};
