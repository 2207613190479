import dayjs from "dayjs";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Button } from "@/components/ui/button";

import { StepOne } from "../step-one";
import { StepFour } from "../step-four";
import { StepFive } from "../step-five";
import { useState } from "react";
import { StepTwo } from "../step-two";
import { StepThree } from "../step-three";
import { AddDevis } from "../add-devis";

const FormSchema = z.object({
  type: z.enum(["all", "mentions", "none"], {
    required_error: "You need to select a notification type.",
  }),
});

export const StepForm = () => {
  const [dossier, setDossier] = useState(null);
  const [step, setStep] = useState(1);
  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
  });

  function onSubmit(data: z.infer<typeof FormSchema>) {
    console.log("data", data);
  }

  const handleChangeStep = (value) => {
    if (value === -1 && step > 1) {
      setStep((prev) => prev - 1);
    } else if (value === 1 && step < 6) {
      setStep((prev) => prev + 1);
    }
  };

  const handleSelectDossier = (newDossier) => {
    setDossier(newDossier);
    setStep(2);
  };

  return (
    <div className="container lg:px-0 px-8 mx-auto mt-16 space-y-14">
      <hr className="border-green-light"></hr>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <div className="flex flex-row gap-8 justify-between">
            <div className="space-y-8 w-1/4">
              <div className="flex flex-row gap-4 bg-white rounded-xl p-4 items-center">
                <img className="w-[100px] h-[100px] object-cover rounded-full" src="/image/profile.jpg" alt="profile" />
                <div className="space-y-2 text-green-light">
                  <p className="font-bold">EN ATTENTE</p>
                  <p>Dossier entreprise N</p>
                  <p>Nom: Roseline</p>
                  <p>{dayjs().format("DD-MM-YYYY")}</p>
                </div>
              </div>
              {dossier && (
                <div className="bg-green-light p-4 rounded-xl flex flex-row gap-8 items-center">
                  <img src={`/image/icon/${dossier.slug}.png`} alt="logo" />
                  <p className="font-semibold text-xl group-hover:font-bold text-left">{dossier.title}</p>
                </div>
              )}
              <div className="p-4 bg-navy-blue-light">
                <p>Etape {step} sur 6</p>
              </div>
            </div>

            {step === 1 && <StepOne handleSelectDossier={handleSelectDossier} />}
            {step === 2 && <StepTwo form={form} />}
            {step === 3 && <StepThree form={form} />}
            {step === 4 && <StepFour form={form} />}
            {step === 5 && <StepFive form={form} />}
            {step === 6 && <AddDevis />}
          </div>
          <Button onClick={() => handleChangeStep(-1)}>Previous</Button>
          <Button onClick={() => handleChangeStep(+1)}>Next</Button>
        </form>
      </Form>
    </div>
  );
};
