export const listDossierContact = [
  {
    slug: "travaux",
    title: "Travaux",
  },
  {
    slug: "construction",
    title: "Construction",
  },
  {
    slug: "renovation-energetique",
    title: "Rénovation énergétique",
  },
  {
    slug: "diagnostique-energetique",
    title: "Diagnostic énergétique",
  },
  {
    slug: "exterieur-jardin",
    title: "Extérieur et jardin",
  },
  {
    slug: "depannage-entretien",
    title: "Dépannage et entretien",
  },
  {
    slug: "architecte-interieur-decorateur",
    title: "Architecte d’intérieur et décorateur",
  },
  {
    slug: "architecte",
    title: "Architecte",
  },
];
