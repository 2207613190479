import { ListProfileType } from "@/modules/avantage-pro/components/list-profile-type";
import { ProfileType } from "@/modules/avantage-pro/components/profile-type";
import { useParams } from "react-router";
export const AvantageProPage = () => {
  let { type } = useParams();

  return (
    <div className="container mx-auto mt-8">
      <div className="lg:p-0 p-8 divide-y divide-green-light">
        <p className="py-3  italic">
          L'appli qui met en avant les professionnels engagés dans des actions
          démontrant leur sérieux face aux consommateurs
        </p>
        <div className="py-3">
          {!type && <ListProfileType />}
          {type && <ProfileType />}
        </div>
      </div>
    </div>
  );
};
