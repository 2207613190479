import { DatePicker } from "@/components/ui/date-picker";
import { Input } from "@/components/ui/input";
import { listDossierContact } from "../../data";
import { BlocTypeDossier } from "../bloc-type-dossier";
import { useState } from "react";
import { ListDossier } from "../list-dossier";

export const DossierInterne = () => {
  const [dossier, setDossier] = useState(null);
  return (
    <div className="container lg:px-0 px-8 mx-auto mt-16">
      <div className="flex flex-row gap-4 items-center w-full justify-between">
        <div className="flex flex-row gap-8">
          <Input className="w-[250px]" placeholder="Recherche Nom / N° Dossier" />
          <DatePicker />
        </div>
      </div>
      <div className="mt-6">
        <div className={`flex flex-row gap-2 flex-wrap justify-between`}>
          {listDossierContact.map((dossier, index) => (
            <BlocTypeDossier key={index} dossier={dossier} setDossier={setDossier} className="mini-bloc" />
          ))}
        </div>
      </div>{" "}
      <ListDossier />
    </div>
  );
};
