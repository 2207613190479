"use client";

import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { Button } from "@/components/ui/button";
import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { Switch } from "@/components/ui/switch";
import { Check, X } from "lucide-react";
import { Input } from "@/components/ui/input";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { PasswordInput } from "@/components/ui/input-password";
import { isValidPhoneNumber } from "react-phone-number-input";
import { PhoneInput } from "@/components/ui/phone-input";
import { useTranslation } from "react-i18next";

const FormSchema = z.object({
  firstname: z.string().min(2, {
    message: "Champ obligatoire.",
  }),
  lastname: z.string().min(2, {
    message: "Champ obligatoire.",
  }),
  email: z.string().min(2, {
    message: "Champ obligatoire.",
  }),
  phoneNumber: z.string().refine(isValidPhoneNumber, { message: "Numero de téléphone invalide." }),

  password: z.string().min(2, {
    message: "Champ obligatoire.",
  }),
});

export function FormRegister() {
  const { t } = useTranslation();
  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
  });

  function onSubmit(data: z.infer<typeof FormSchema>) {
    console.log("data", data);
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="w-full p-4 flex flex-col justify-between gap-8 h-[calc(100%-16px)]">
        <div className="space-y-4 h-full">
          <span className="text-sm">Renseignez ses informations</span>
          <div className="grid grid-cols-2 gap-6">
            <FormField
              control={form.control}
              name={"lastname"}
              render={({ field }) => (
                <FormItem className="flex w-full flex-col items-start">
                  <FormControl>
                    <Input className="py-6 border-green-light" placeholder={t("FORM.NAME")} {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name={"firstname"}
              render={({ field }) => (
                <FormItem className="flex w-full flex-col items-start">
                  <FormControl>
                    <Input className="py-6 border-green-light" placeholder={t("FORM.FIRSTNAME")} {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>

          <FormField
            control={form.control}
            name="phoneNumber"
            render={({ field }) => (
              <FormItem className="flex w-full flex-col items-start">
                <FormControl className="w-full">
                  <PhoneInput defaultCountry="FR" placeholder="Téléphone" {...field} />
                </FormControl>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name={"email"}
            render={({ field }) => (
              <FormItem className="flex w-full flex-col items-start">
                <FormControl>
                  <Input className="py-6 border-green-light" placeholder="Email" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name={"password"}
            render={({ field }) => (
              <FormItem className="flex w-full flex-col items-start">
                <FormControl>
                  <PasswordInput className="py-6 border-green-light" placeholder={t("FORM.PASSWORD")} {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <Button className="btn-green" type="submit">
          Inviter
        </Button>
      </form>
    </Form>
  );
}
