import "./style.scss";

export const BlocTypeDossier = ({ dossier, setDossier, className }: { dossier: any; setDossier: any; className?: string }) => {
  return (
    <div
      className={`bg-transparent border border-green-light flex flex-row gap-8 min-h-[120px] rounded-2xl items-center hover:cursor-pointer hover:bg-green-light px-8 ${className}`}
      onClick={() => setDossier(dossier)}
    >
      <img src={`/image/icon/${dossier.slug}.png`} alt="logo" />
      <p className="font-semibold text-lg text-left">{dossier.title}</p>
    </div>
  );
};
