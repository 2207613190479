"use client";

import {
  NavigationMenu,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  navigationMenuTriggerStyle,
} from "@/components/ui/navigation-menu";
import { Link } from "react-router-dom";

export function MenuTop() {
  return (
    <NavigationMenu>
      <NavigationMenuList>
        <NavigationMenuItem>
          <Link to="/agenda">
            <NavigationMenuLink className={navigationMenuTriggerStyle()}>Agenda</NavigationMenuLink>
          </Link>
          <Link to="/creation-dossier">
            <NavigationMenuLink className={navigationMenuTriggerStyle()}>Création Dossier</NavigationMenuLink>
          </Link>
          <Link to="/demande-devis">
            <NavigationMenuLink className={navigationMenuTriggerStyle()}>Demande devis</NavigationMenuLink>
          </Link>
          <Link to="/suivi-devis">
            <NavigationMenuLink className={navigationMenuTriggerStyle()}>Suivi devis</NavigationMenuLink>
          </Link>
          <Link to="/suivi-travaux">
            <NavigationMenuLink className={navigationMenuTriggerStyle()}>Suivi travaux</NavigationMenuLink>
          </Link>
          <Link to="/tarifs">
            <NavigationMenuLink className={navigationMenuTriggerStyle()}>Tarifs</NavigationMenuLink>
          </Link>
          <Link to="/parametres">
            <NavigationMenuLink className={navigationMenuTriggerStyle()}>Paramètres</NavigationMenuLink>
          </Link>
        </NavigationMenuItem>
      </NavigationMenuList>
    </NavigationMenu>
  );
}
