import { Button } from "@/components/ui/button";
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import SwitcherCustom from "@/components/ui/switch-button";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";
import { isValidPhoneNumber } from "react-phone-number-input";
import { zodResolver } from "@hookform/resolvers/zod";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { PhoneInput } from "@/components/ui/phone-input";
import { PasswordInput } from "@/components/ui/input-password";
import { Checkbox } from "@/components/ui/checkbox";

const FormSchema = z.object({
  firstname: z.string().min(2, {
    message: "Champ obligatoire.",
  }),
  lastname: z.string().min(2, {
    message: "Champ obligatoire.",
  }),
  email: z.string().min(2, {
    message: "Champ obligatoire.",
  }),
  phoneNumber: z.string().refine(isValidPhoneNumber, { message: "Numero de téléphone invalide." }),
  isProfessional: z.boolean(),
  siret: z.string().optional(),
  password: z.string().min(2, {
    message: "Champ obligatoire.",
  }),
  confirmPassword: z.string().optional(),
  address: z.string().optional(),
  accept: z.boolean(),
});

export function RegisterProfessionalForm() {
  const { t } = useTranslation();
  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      firstname: "",
      lastname: "",
      email: "",
      phoneNumber: "",
      isProfessional: false,
      siret: "",
      address: "",
      accept: false,
    },
  });

  const isProfessional = form.watch("isProfessional");

  function onSubmit(data: z.infer<typeof FormSchema>) {
    console.log(data);
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4 w-2/3">
        <div className="grid grid-cols-2 gap-6">
          <FormField
            control={form.control}
            name={"lastname"}
            render={({ field }) => (
              <FormItem className="flex w-full flex-col items-start">
                <FormControl>
                  <Input className="py-6 border-green-light" placeholder={t("FORM.NAME")} {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name={"firstname"}
            render={({ field }) => (
              <FormItem className="flex w-full flex-col items-start">
                <FormControl>
                  <Input className="py-6 border-green-light" placeholder={t("FORM.FIRSTNAME")} {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name={"email"}
            render={({ field }) => (
              <FormItem className="flex w-full flex-col items-start">
                <FormControl>
                  <Input className="py-6 border-green-light" placeholder="Email" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          {/* {!isProfessional && (
            <FormField
              control={form.control}
              name="phoneNumber"
              render={({ field }) => (
                <FormItem className="flex w-full flex-col items-start">
                  <FormControl className="w-full">
                    <PhoneInput defaultCountry="FR" placeholder="Téléphone" {...field} />
                  </FormControl>
                </FormItem>
              )}
            />
          )} */}
          <FormField
            control={form.control}
            name={"password"}
            render={({ field }) => (
              <FormItem className="flex w-full flex-col items-start">
                <FormControl>
                  <PasswordInput className="py-6 border-green-light" placeholder={t("FORM.PASSWORD")} {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name={"siret"}
            render={({ field }) => (
              <FormItem className="flex w-full flex-col items-start">
                <FormControl>
                  <Input className="py-6 border-green-light" placeholder={t("FORM.SIRET")} {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name={"confirmPassword"}
            render={({ field }) => (
              <FormItem className="flex w-full flex-col items-start">
                <FormControl>
                  <PasswordInput className="py-6 border-green-light" placeholder={t("AUTH.CONFIRM_PASSWORD")} {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          {!isProfessional && (
            <FormField
              control={form.control}
              name={"address"}
              render={({ field }) => (
                <FormItem className="flex w-full flex-col items-start">
                  <FormControl>
                    <Input className="py-6 border-green-light" placeholder={t("FORM.ADDRESS")} {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          )}
        </div>
        <FormField
          control={form.control}
          name="accept"
          render={({ field }) => (
            <FormItem className="flex flex-row items-start space-x-3 space-y-0 rounded-md py-4">
              <FormControl>
                <Checkbox checked={field.value} onCheckedChange={field.onChange} />
              </FormControl>
              <div className="space-y-1 leading-none">
                <FormLabel>{t("FORM.ACCEPT_TERMS")}</FormLabel>
              </div>
            </FormItem>
          )}
        />
        <Button type="submit" className="btn-green mt-4 px-6 py-6">
          Connexion
        </Button>
      </form>
    </Form>
  );
}
