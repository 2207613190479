import { Button } from "@/components/ui/button";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { PhoneInput } from "@/components/ui/phone-input";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";

const FormSchema = z.object({
  firstname: z.string().min(2, {
    message: "Champ obligatoire.",
  }),
  lastname: z.string().min(2, {
    message: "Champ obligatoire.",
  }),
  phoneNumber: z.string(),
  email: z.string().email(),
});

export const ShareQRCode = () => {
  const { t } = useTranslation();
  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      email: "",
      lastname: "",
      firstname: "",
      phoneNumber: null,
    },
  });

  function onSubmit(data: z.infer<typeof FormSchema>) {
    console.log(data);
  }
  return (
    <div className="container lg:px-0 px-8 mx-auto mt-16">
      <div className="flex justify-between flex-row gap-8">
        <div className="space-y-14">
          <h1 className="font-semibold leading-none">Un bon plan, ça se partage !</h1>
          <Form {...form}>
            <form
              onSubmit={form.handleSubmit(onSubmit)}
              className="w-full lg:max-w-md flex flex-col gap-8 justify-center lg:items-start items-center"
            >
              <FormField
                control={form.control}
                name={"lastname"}
                render={({ field }) => (
                  <FormItem className="flex w-full flex-col items-start">
                    <FormControl>
                      <Input className="py-6 border-green-light" placeholder={t("FORM.NAME")} {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name={"lastname"}
                render={({ field }) => (
                  <FormItem className="flex w-full flex-col items-start">
                    <FormControl>
                      <Input className="py-6 border-green-light" placeholder={t("FORM.NAME")} {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="phoneNumber"
                render={({ field }) => (
                  <FormItem className="flex w-full flex-col items-start">
                    <FormControl className="w-full">
                      <PhoneInput defaultCountry="FR" placeholder="Téléphone" {...field} />
                    </FormControl>
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name={"email"}
                render={({ field }) => (
                  <FormItem className="flex w-full flex-col items-start">
                    <FormControl>
                      <Input className="py-6 border-green-light" placeholder="Email" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <Button type="submit" className="btn-green mt-4 px-6 py-6">
                Envoyé
              </Button>
            </form>
          </Form>
        </div>
      </div>
    </div>
  );
};
