import { typeProfile } from "@/contants/type-profile";
import { useNavigate } from "react-router-dom";

export const ListProfileType = () => {
  const navigate = useNavigate();
  const handleRedirectType = (type) => {
    navigate(`/avantages-pro/${type.slug}`);
  };

  return (
    <div>
      <h4 className="font-semi-bold">
        Découvrez les avantages de l’application selon votre profil
      </h4>
      <div className="mt-8 mx-auto grid grid-cols-4 gap-4">
        {typeProfile.map((type, key) => (
          <div
            key={key}
            className="border-2 border-green-light rounded-2xl p-3 hover:bg-green-light hover:text-white hover:cursor-pointer"
            onClick={() => handleRedirectType(type)}
          >
            <p className="text-center">{type.name}</p>
          </div>
        ))}
      </div>
    </div>
  );
};
