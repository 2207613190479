import { PropsWithChildren, useEffect } from "react";
import useWindowSize from "@/hooks/useWindowSize";
import UiStore from "@/store/ui";
import { NavBar } from "./navbar";
import { useLocation } from "react-router-dom";

const Layout = ({ children }: PropsWithChildren<any>) => {
  const [width] = useWindowSize();
  const { setShowMenu } = UiStore();
  const location = useLocation();
  const isBasePath = location.pathname === "/";
  console.log("isBasePath", isBasePath);
  useEffect(() => {
    setShowMenu(width >= 768);
  }, [width, setShowMenu]);

  return (
    <div
      className={`${isBasePath ? "bg-with-phone" : ""} h-full w-screen bg-navy-blue`}
    >
      {/* {!isBasePath && <BaseMenu />} */}
      <NavBar />
      <div className="w-full lg:h-[calc(100%-100px)] h-auto m-auto overflow-hidden">
        {children}
      </div>
    </div>
  );
};

export default Layout;
