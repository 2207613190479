import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import SwitcherCustom from "@/components/ui/switch-button";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";
import { isValidPhoneNumber } from "react-phone-number-input";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { PasswordInput } from "@/components/ui/input-password";
import { Checkbox } from "@/components/ui/checkbox";

const FormSchema = z.object({
  email: z.string().min(2, {
    message: "Champ obligatoire.",
  }),
  password: z.string().min(2, {
    message: "Champ obligatoire.",
  }),
  forgotPassword: z.boolean(),
});

export function Login() {
  const { t } = useTranslation();
  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      email: "",
      password: "",
      forgotPassword: false,
    },
  });

  function onSubmit(data: z.infer<typeof FormSchema>) {
    console.log(data);
  }

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="w-full lg:max-w-3xl max-w-md px-8 flex lg:flex-row flex-col gap-8 justify-center lg:items-start items-center"
      >
        <div className="lg:w-auto w-full">
          <h1 className="font-bold lg:text-5xl text-3xl lg:w-auto w-52">
            IDENTIFIEZ VOUS
          </h1>
        </div>
        <div className="space-y-4 w-full">
          <div className="flex flex-col gap-4">
            <Input
              className="py-6 border-green-light"
              id="email"
              placeholder={t("FORM.EMAIL")}
            />
            <FormField
              control={form.control}
              name={"password"}
              render={({ field }) => (
                <FormItem className="flex w-full flex-col items-start">
                  <FormControl>
                    <PasswordInput
                      className="py-6 border-green-light"
                      placeholder={t("FORM.PASSWORD")}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="forgotPassword"
              render={({ field }) => (
                <FormItem className="flex flex-row items-start space-x-3 space-y-0 rounded-md py-4">
                  <FormControl>
                    <Checkbox
                      checked={field.value}
                      onCheckedChange={field.onChange}
                    />
                  </FormControl>
                  <div className="space-y-1 leading-none">
                    <FormLabel>Mot de passe oubliez</FormLabel>
                  </div>
                </FormItem>
              )}
            />
          </div>
          <div className="flex justify-end flex-row">
            <Button className="btn-green">Suivant</Button>
          </div>
        </div>
      </form>
    </Form>
  );
}
