export const typeProfile = [
  {
    id: 1,
    slug: "professionnel",
    name: "Professionnel du bâtiment",
    next: "/fabricant",
    previous: "/",
    description:
      "<p>Inscrivez-vous gratuitement  pour consulter les demandes de travaux et découvrir nos prestations</p><br><p>Faites la différence avec la concurrence, obtenez des chantiers en réalisant des actions rassurantes exprimant votre sérieux, auprès des consommateurs. Capter une clientèle ayant les moyens de financer des travaux au juste prix. Réduisez de manière significative votre gestion administrative et commerciale</p>",
  },
  {
    id: 2,
    slug: "fabricant",
    name: "Fabricant",
    next: "/grossiste",
    previous: "/professionnel",
    description:
      "<p>Inscrivez-vous gratuitement  pour consulter les demandes de travaux et découvrir nos prestations</p><br><p>Faites la différence avec la concurrence, obtenez des chantiers en réalisant des actions rassurantes exprimant votre sérieux, auprès des consommateurs. Capter une clientèle ayant les moyens de financer des travaux au juste prix. Réduisez de manière significative votre gestion administrative et commerciale</p>",
  },
  {
    id: 3,
    slug: "grossiste",
    name: "Grossiste",
    next: "/distributeur",
    previous: "/fabricant",
    description:
      "<p>Inscrivez-vous gratuitement  pour consulter les demandes de travaux et découvrir nos prestations</p><br><p>Faites la différence avec la concurrence, obtenez des chantiers en réalisant des actions rassurantes exprimant votre sérieux, auprès des consommateurs. Capter une clientèle ayant les moyens de financer des travaux au juste prix. Réduisez de manière significative votre gestion administrative et commerciale</p>",
  },
  {
    id: 3,
    slug: "distributeur",
    name: "Distributeur",
    next: "/architecte",
    previous: "/grossiste",
    description:
      "<p>Inscrivez-vous gratuitement  pour consulter les demandes de travaux et découvrir nos prestations</p><br><p>Faites la différence avec la concurrence, obtenez des chantiers en réalisant des actions rassurantes exprimant votre sérieux, auprès des consommateurs. Capter une clientèle ayant les moyens de financer des travaux au juste prix. Réduisez de manière significative votre gestion administrative et commerciale</p>",
  },
  {
    id: 3,
    slug: "architecte",
    name: "Architecte",
    next: "/architecte-interieur",
    previous: "/distributeur",
    description:
      "<p>Inscrivez-vous gratuitement  pour consulter les demandes de travaux et découvrir nos prestations</p><br><p>Faites la différence avec la concurrence, obtenez des chantiers en réalisant des actions rassurantes exprimant votre sérieux, auprès des consommateurs. Capter une clientèle ayant les moyens de financer des travaux au juste prix. Réduisez de manière significative votre gestion administrative et commerciale</p>",
  },
  {
    id: 3,
    slug: "architecte-interieur",
    name: "Architecte d’intérieur",
    next: "/decorateur-interieur",
    previous: "/architecte",
    description:
      "<p>Inscrivez-vous gratuitement  pour consulter les demandes de travaux et découvrir nos prestations</p><br><p>Faites la différence avec la concurrence, obtenez des chantiers en réalisant des actions rassurantes exprimant votre sérieux, auprès des consommateurs. Capter une clientèle ayant les moyens de financer des travaux au juste prix. Réduisez de manière significative votre gestion administrative et commerciale</p>",
  },
  {
    id: 3,
    slug: "decorateur-interieur",
    name: "Décorateur d’intérieur",
    next: "/diagnostiqueur",
    previous: "/architecte-interieur",
    description:
      "<p>Inscrivez-vous gratuitement  pour consulter les demandes de travaux et découvrir nos prestations</p><br><p>Faites la différence avec la concurrence, obtenez des chantiers en réalisant des actions rassurantes exprimant votre sérieux, auprès des consommateurs. Capter une clientèle ayant les moyens de financer des travaux au juste prix. Réduisez de manière significative votre gestion administrative et commerciale</p>",
  },
  {
    id: 3,
    slug: "diagnostiqueur",
    name: "Diagnostiqueur",
    next: "/agence-immobiliere",
    previous: "/decorateur-interieur",
    description:
      "<p>Inscrivez-vous gratuitement  pour consulter les demandes de travaux et découvrir nos prestations</p><br><p>Faites la différence avec la concurrence, obtenez des chantiers en réalisant des actions rassurantes exprimant votre sérieux, auprès des consommateurs. Capter une clientèle ayant les moyens de financer des travaux au juste prix. Réduisez de manière significative votre gestion administrative et commerciale</p>",
  },
  {
    id: 3,
    slug: "agence-immobiliere",
    name: "Agence immobilière",
    next: "/syndic",
    previous: "/diagnostiqueur",
    description:
      "<p>Inscrivez-vous gratuitement  pour consulter les demandes de travaux et découvrir nos prestations</p><br><p>Faites la différence avec la concurrence, obtenez des chantiers en réalisant des actions rassurantes exprimant votre sérieux, auprès des consommateurs. Capter une clientèle ayant les moyens de financer des travaux au juste prix. Réduisez de manière significative votre gestion administrative et commerciale</p>",
  },
  {
    id: 3,
    slug: "syndic",
    name: "Syndic",
    next: "/agence-immobilier",
    previous: "/agence-immobiliere",
    description:
      "<p>Inscrivez-vous gratuitement  pour consulter les demandes de travaux et découvrir nos prestations</p><br><p>Faites la différence avec la concurrence, obtenez des chantiers en réalisant des actions rassurantes exprimant votre sérieux, auprès des consommateurs. Capter une clientèle ayant les moyens de financer des travaux au juste prix. Réduisez de manière significative votre gestion administrative et commerciale</p>",
  },
  {
    id: 3,
    slug: "agence-immobilier",
    name: "Agent immobilier",
    next: "/delagataire",
    previous: "/syndic",
    description:
      "<p>Inscrivez-vous gratuitement  pour consulter les demandes de travaux et découvrir nos prestations</p><br><p>Faites la différence avec la concurrence, obtenez des chantiers en réalisant des actions rassurantes exprimant votre sérieux, auprès des consommateurs. Capter une clientèle ayant les moyens de financer des travaux au juste prix. Réduisez de manière significative votre gestion administrative et commerciale</p>",
  },
  {
    id: 3,
    slug: "delagataire",
    name: "Délégataire",
    next: "/mon-accompagnateur-renov",
    previous: "/agence-immobilier",
    description:
      "<p>Inscrivez-vous gratuitement  pour consulter les demandes de travaux et découvrir nos prestations</p><br><p>Faites la différence avec la concurrence, obtenez des chantiers en réalisant des actions rassurantes exprimant votre sérieux, auprès des consommateurs. Capter une clientèle ayant les moyens de financer des travaux au juste prix. Réduisez de manière significative votre gestion administrative et commerciale</p>",
  },
  {
    id: 3,
    slug: "mon-accompagnateur-renov",
    name: "Mon Accompagnateur Rénov",
    next: "/consommateur",
    previous: "/delagataire",
    description:
      "<p>Inscrivez-vous gratuitement  pour consulter les demandes de travaux et découvrir nos prestations</p><br><p>Faites la différence avec la concurrence, obtenez des chantiers en réalisant des actions rassurantes exprimant votre sérieux, auprès des consommateurs. Capter une clientèle ayant les moyens de financer des travaux au juste prix. Réduisez de manière significative votre gestion administrative et commerciale</p>",
  },
  {
    id: 3,
    slug: "consommateur",
    name: "Consommateur",
    next: "/professionnel",
    previous: "/mon-accompagnateur-renov",
    description:
      "<p>Inscrivez-vous gratuitement  pour consulter les demandes de travaux et découvrir nos prestations</p><br><p>Faites la différence avec la concurrence, obtenez des chantiers en réalisant des actions rassurantes exprimant votre sérieux, auprès des consommateurs. Capter une clientèle ayant les moyens de financer des travaux au juste prix. Réduisez de manière significative votre gestion administrative et commerciale</p>",
  },
];
