import { Button } from "@/components/ui/button";
import { useLocation, useNavigate } from "react-router-dom";
import { MenuTop } from "../menu-top";

export const NavBar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const handleGoTo = (url) => {
    navigate(url);
  };

  return (
    <div className="container bg-navy-blue lg:px-0 px-8 mx-auto sticky top-0 z-50 w-full flex justify-between py-8 items-center h-[100px]">
      <div>
        <img
          src="/image/logo.png"
          alt="logo"
          width={180}
          height={180}
          onClick={() => handleGoTo("/")}
          className="hover:cursor-pointer"
        />
      </div>
      <div className="flex gap-3">
        <MenuTop />
        {!location.pathname.includes("register") && (
          <Button className="btn-green" onClick={() => handleGoTo("/register")}>
            Inscription
          </Button>
        )}
        {!location.pathname.includes("login") && (
          <Button
            variant="outline"
            className="text-sm font-medium focus:outline-none rounded-lg border"
            onClick={() => handleGoTo("/login")}
          >
            Connexion
          </Button>
        )}
      </div>
    </div>
  );
};
