import { Camera } from "lucide-react";

export function BoxUploadMedia({ inputChangeHandler, inputRef }) {
  return (
    <div
      className="border border-dotted border-green-light p-4 rounded-lg w-[150px] h-[150px] hover:cursor-pointer place-items-center flex"
      onClick={() => inputRef.current.click()}
    >
      <div className="flex flex-col items-center gap-2 justify-center w-full">
        <Camera className="w-12 h-12 text-green-light" />
        <input type="file" hidden ref={inputRef} accept=".png, .jpg, .jpeg, .mp4, .mov, .m4v" onChange={inputChangeHandler} multiple />
      </div>
    </div>
  );
}
