import { Error404 } from "@/components/errors/400";
import { AvantageProPage } from "@/pages/avantage-pro";
import { CreationDossierPage } from "@/pages/creation-dossier";
import { EspaceParametres } from "@/pages/espace-parametres";
import { HomePage } from "@/pages/home";
import { LoginPage } from "@/pages/login";
import { RegisterPage } from "@/pages/register";
import { RegisterProfessionalPage } from "@/pages/register/professional";
import { EspaceTarifs } from "@/pages/tarifs";
import { createBrowserRouter } from "react-router-dom";
import GlobalOutlet from "./global-outlet";
import PrivateOutlet from "./private-outlet";
import PublicOutlet from "./public-outlet";
import { DemandeDevisPage } from "@/pages/demande-devis";
import { StepFormCreation } from "@/pages/creation-dossier/step-form";

export const listRoutes = createBrowserRouter([
  {
    path: "/",
    element: <GlobalOutlet />,
    children: [
      {
        path: "*",
        element: <Error404 />,
      },
      {
        element: <PublicOutlet />,
        path: "/",
        children: [
          {
            element: <HomePage />,
            path: "/",
          },
          {
            path: "login",
            element: <LoginPage />,
          },
          {
            path: "register",
            children: [
              {
                path: "/register",
                element: <RegisterPage />,
              },
              {
                path: "/register/professional",
                element: <RegisterProfessionalPage />,
              },
            ],
          },
          {
            element: <AvantageProPage />,
            path: "avantages-pro",
            children: [
              {
                path: ":type",
                element: <AvantageProPage />,
              },
            ],
          },
        ],
      },
      {
        element: <PrivateOutlet />,
        path: "/",
        children: [
          {
            element: <EspaceParametres />,
            path: "/parametres",
          },
          {
            element: <EspaceTarifs />,
            path: "/tarifs",
          },
          {
            element: <DemandeDevisPage />,
            path: "/demande-devis",
          },
          {
            path: "/creation-dossier",
            children: [
              {
                element: <CreationDossierPage />,
                path: "/creation-dossier",
              },
              {
                element: <StepFormCreation />,
                path: "/creation-dossier/step-form",
              },
            ],
          },
        ],
      },
    ],
  },
]);
