import { CookiesService } from "..";

class LangService {
  preloadLanguage = (languageList: any) => {
    let selectLang = "en";
    let breakOuterLoop = false;
    for (const lang of languageList) {
      const prefix = lang.split("-");
      const languageCode = prefix[0]?.toUpperCase();
      const languageRegion = prefix[1]?.toUpperCase();

      switch (languageCode) {
        case "EN":
          breakOuterLoop = true;
          break;
        case "FR":
        case "BE":
          selectLang = "fr-CA";
          if (languageRegion === "FR" || languageRegion === "BE") {
            selectLang = "fr-FR";
          }
          breakOuterLoop = true;
          break;
      }
      if (breakOuterLoop) {
        break;
      }
    }
    CookiesService.set({ lang: selectLang });
    return selectLang;
  };
}
export default new LangService();
