import dayjs from "dayjs";
import { BlocItemsDossier } from "../bloc-items-dossier";

export const ListDossier = () => {
  return (
    <div className="container lg:px-0 px-8 mx-auto mt-16">
      <div className="grid grid-cols-3 gap-8">
        <BlocItemsDossier dossier={{ slug: "travaux", title: "Travaux" }} />
        <BlocItemsDossier dossier={{ slug: "travaux", title: "Travaux" }} />
        <BlocItemsDossier dossier={{ slug: "travaux", title: "Travaux" }} />
        <BlocItemsDossier dossier={{ slug: "travaux", title: "Travaux" }} />
        <BlocItemsDossier dossier={{ slug: "travaux", title: "Travaux" }} />
      </div>
    </div>
  );
};
