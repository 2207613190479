import { Button } from "@/components/ui/button";
import { useState } from "react";
import { EditedProfile } from "../edited-form";

export const Overview = () => {
  const [isEditable, setIsEditable] = useState(false);

  const handleEdit = () => {
    setIsEditable(!isEditable);
  };

  return (
    <div className="container lg:px-0 px-8 mx-auto mt-16">
      {!isEditable && (
        <div className="flex flex-row lg:flex-nowrap flex-wrap gap-8">
          <div className="space-y-4 lg:w-1/2 w-full">
            <div className="flex flex-row gap-12">
              <div>
                <img
                  src="/image/profile.jpg"
                  alt="logo"
                  width={310}
                  height={310}
                  className="rounded-2xl"
                />
              </div>
              <div>
                <h2 className="font-bold">CARLOS</h2>
                <h4>Peitrin</h4>
                <p>carlospetr@gmail.com</p>
                <p>+33 45 678 3422</p>
                <p>France, Lyon</p>
                <p>Siret</p>
              </div>
            </div>
            <div className="space-y-4">
              <p>Description</p>
              <p>
                Faites la différence avec la concurrence, obtenez des chantiers
                en réalisant des actions rassurantes exprimant votre sérieux,
                auprès des consommateurs. Captez une clientèle ayant les moyens
                de financer des travaux au juste prix. Réduisez de manière
                significative votre gestion administrative et commerciale
              </p>
            </div>
          </div>
          <div className="lg:w-1/2 w-full">
            <div className="space-y-12">
              <div className="flex justify-end">
                <Button
                  className="btn-green w-fit"
                  onClick={() => handleEdit()}
                >
                  Modifier
                </Button>
              </div>
              <img
                src="/image/tag-qr.png"
                alt="logo"
                width="100%"
                className="rounded-2xl"
              />
            </div>
          </div>
        </div>
      )}
      {isEditable && <EditedProfile handleEdit={handleEdit} />}
    </div>
  );
};
