import { z } from "zod";
import { FormInvitation } from "./form-invitation";
import { FormRegister } from "./form-register";

export const CreateDossierContact = () => {
  return (
    <div className="container lg:px-0 px-8 mx-auto mt-12">
      <div>
        <h1 className="text-2xl font-bold uppercase text-center">Créer un dossier client</h1>
        <div className="mt-4 italic">
          <p className="text-center">Mettez tous les atous de votre coté pour rassuer voter client et gagner plus de chantiers</p>
          <p className="text-center">Facilitez votre gestion commerciale et administrative à chaque étape de vos travaux !</p>
        </div>
      </div>
      <div className="mt-8 flex flex-row md:flex-nowrap flex-wrap gap-6">
        <div className="md:w-1/2 w-full rounded-lg border border-green-light min-h-[500px] p-4 h-auto">
          <div className="h-full flex flex-col gap-8">
            <p className="text-xl text-center font-bold">Invitation de votre client</p>
            <FormInvitation />
          </div>
        </div>
        <div className="md:w-1/2 w-full rounded-lg border border-green-light min-h-[500px] p-4">
          <div className="h-full flex flex-col gap-6">
            <p className="text-xl text-center font-bold">Votre client n'est pas inscrit</p>
            <FormRegister />
          </div>
        </div>
      </div>
    </div>
  );
};
