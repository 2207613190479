import { FormControl, FormField, FormItem, FormMessage } from "@/components/ui/form";
import { Textarea } from "@/components/ui/textarea";
import { MediaPhoto } from "@/modules/media/components/bloc-photo";
import { BoxUploadMedia } from "@/modules/media/components/upload-media";
import { mediaService } from "@/modules/media/service";
import { ChangeEvent, useCallback, useRef, useState } from "react";

export const StepFive = ({ form }) => {
  const inputRef = useRef<HTMLInputElement>();
  const [mediasLocal, setMediasLocal] = useState([]);

  const handleMedias = useCallback(
    async (mediaFiles: FileList) => {
      if (mediaFiles?.length > 0) {
        const res = await mediaService.mediaUploadList(mediasLocal, mediaFiles);
        const existingMedias = mediaService.groupMediaByType([...(mediasLocal ?? [])]);
        const photoNumber = existingMedias.images.length;
        const videoNumber = existingMedias.video.length;
        const totalExistingMedia = photoNumber + videoNumber;

        const medias = res.medias;
        const images = medias.images.slice(0, 10 - photoNumber);
        const videos = medias.video.slice(0, 1 - videoNumber);
        const mediaList = [...images, ...videos].slice(0, 10 - totalExistingMedia);
        if (mediaList.length > 0) {
          if (mediasLocal) {
            const newTab = [...(mediasLocal ?? []), ...(mediaList as File[])];
            setMediasLocal(newTab);
          } else {
            setMediasLocal(mediaList as File[]);
          }
        }
      }
    },
    [mediasLocal]
  );

  const handleImage = useCallback(
    async (mediaFiles: FileList) => {
      if (mediaFiles?.length > 0) {
        handleMedias(mediaFiles);
      }
    },
    [handleMedias]
  );

  const inputChangeHandler = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      handleImage(e.target.files);
    },
    [handleImage]
  );

  const handleRemoveMedia = (index) => {
    const newTab = [...(mediasLocal ?? [])];
    newTab.splice(index, 1);
    setMediasLocal(newTab);
  };

  return (
    <div className="space-y-8 w-3/4">
      <div className="bg-navy-blue-light px-4 py-2">
        <p>Détaillez votre demande avec des photos (max 10 /format HD)</p>
      </div>
      <div className="flex flex-row gap-8 flex-wrap">
        <BoxUploadMedia inputRef={inputRef} inputChangeHandler={inputChangeHandler} />
        {mediasLocal.map((media, index) => (
          <MediaPhoto
            key={index}
            url={URL.createObjectURL(media.file)}
            name={media.file?.name}
            handleRemove={handleRemoveMedia}
            index={index}
          />
        ))}
      </div>

      <div className="bg-green-light px-4 py-2 rounded-lg uppercase">
        <p>Renseignez l’adresse de chantier</p>
      </div>
      <FormField
        control={form.control}
        name={"address_chantier"}
        render={({ field }) => (
          <FormItem className="flex w-full flex-col items-start">
            <FormControl>
              <Textarea placeholder="Adresse de chantier" id="message" {...field} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <div className="bg-green-light px-4 py-2 rounded-lg uppercase">
        <p>Souhaitez vous nous en dire plus sur votre projet ?</p>
      </div>
      <FormField
        control={form.control}
        name={"comments"}
        render={({ field }) => (
          <FormItem className="flex w-full flex-col items-start">
            <FormControl>
              <Textarea placeholder="Plus d'information" id="message" {...field} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
    </div>
  );
};
