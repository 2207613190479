import { Button } from "@/components/ui/button";
import { typeProfile } from "@/contants/type-profile";
import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { RegisterProfessionalForm } from "./form";

export const ProfessionalRegister = () => {
  const [profile, setProfile] = useState(null);
  const navigate = useNavigate();

  const handleGoTo = (type) => {
    navigate(`/register/${type}`);
  };

  const profileProfessionel = useMemo(() => {
    return typeProfile.filter(
      (profile) => !["professionnel", "consommateur"].includes(profile.slug),
    );
  }, [typeProfile]);

  const description = useMemo(() => {
    if (profile) {
      return `Informations d’entreprise ${profile.name}`;
    }
    return "Choississez votre profile";
  }, [profile]);

  return (
    <div className="lg:p-0 px-8 pb-8">
      <h1 className="font-bold">INSCRIPTION</h1>
      <p className="text-xl font-semibold">{description}</p>
      {!profile && (
        <div className="mt-16 mx-auto grid lg:grid-cols-4  md:grid-cols-3 sm:grid-cols-2 gap-4">
          {profileProfessionel.map((type, key) => (
            <div
              key={key}
              className="flex items-center justify-center border-2 border-white rounded-2xl p-3 hover:bg-green-light hover:text-white hover:cursor-pointer"
              onClick={() => setProfile(type)}
            >
              <p className="text-center">{type.name}</p>
            </div>
          ))}
        </div>
      )}
      {profile && (
        <div className="mt-8">
          <RegisterProfessionalForm />
        </div>
      )}
    </div>
  );
};
