import UserSessionStore from "@/store/user-session";
import { Outlet } from "react-router-dom";
import useRedirect from "./useRedirect";

const PrivateOutlet = () => {
  // useRedirect();
  const { isLoggedIn } = UserSessionStore();

  return <>{!isLoggedIn ? <Outlet /> : <></>}</>;
};

export default PrivateOutlet;
