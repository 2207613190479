import { Button } from "@/components/ui/button";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

export const Register = () => {
  const [type, setType] = useState("");
  const navigate = useNavigate();
  const handleChangeType = (value) => {
    setType(value);
  };

  const handleGoTo = (type) => {
    navigate(`/register/${type}`);
  };

  return (
    <div className="flex items-center flex-col gap-12">
      <div className="space-y-4 place-items-center">
        <h1 className="font-bold">INSCRIPTION</h1>
        <p className="text-xl font-semibold">Choississez votre profil</p>
      </div>
      <div className="flex flex-row gap-4 mt-6 flex-wrap justify-center items-center">
        <div
          className={`p-12 border-4 w-[300px] border-green-light rounded-lg text-center hover:bg-green-light hover:cursor-pointer hover:font-bold ${
            type === "particular" ? "bg-green-light text-white" : ""
          }`}
          onClick={() => handleChangeType("particular")}
        >
          <p>Particulier</p>
        </div>
        <div
          className={`p-12 border-4 w-[300px] border-green-light rounded-lg text-center hover:bg-green-light hover:cursor-pointer hover:font-bold ${
            type === "professional" ? "bg-green-light text-white" : ""
          }`}
          onClick={() => handleChangeType("professional")}
        >
          <p>Professionnel</p>
        </div>
      </div>
      <Button
        className="btn-green mt-4 px-6 py-6"
        onClick={() => handleGoTo(type)}
      >
        Suivant
      </Button>
    </div>
  );
};
