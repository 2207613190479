import { Button } from "@/components/ui/button";
import { BlocGift } from "../bloc-gift";

const listGifts = [
  {
    price: 34,
    descriptionPrice:
      "<p class='text-center w-[90%] m-auto'><span>au lieu de 31€  </span><br><span> *offre valable jusqu’au 31 Janvier</span></p>",
    name: "Adhésion annuelle",
    description: "Paiement des douze mensualités en une seule fois Profitez d'une offre au meilleur prix",
    descriptionEco: "Economisez *144€ sur l’année",
    type: "year",
  },
  {
    price: 59,
    descriptionPrice: "<p class='text-center'>Paiement mensuel engagement 12 mois</p>",
    name: "Adhésion mensuelle",
    type: "month",
    description:
      " Paiement prélevé mensuellement Préservez votre trésorerie Diffusez votre publicité sur un support viral visible par les utilisateurs liés à votre activité Optimisez  votre offre gratuite auprès de vos clients",
  },
];
export const InfoGift = () => {
  return (
    <div className="container lg:px-0 px-8 mx-auto mt-16">
      <div className="lg:w-3/5 w-full space-y-32">
        <h1 className="font-semibold leading-none">Valorisez votre cadeau gratuit auprès de vos clients</h1>
        <div className="flex flex-row gap-8">
          {listGifts.map((gift, index) => (
            <BlocGift key={index} gift={gift} />
          ))}
        </div>
      </div>
    </div>
  );
};
