import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Overview } from "@/modules/profile/components/overview";
import { ShareQRCode } from "@/modules/qr-code/components/client-professional";

export function EspaceParametres() {
  return (
    <div className="container lg:px-0 px-8 mx-auto mt-8 ">
      <Tabs defaultValue="information">
        <TabsList className="flex gap-4 justify-start flex-wrap h-auto border-b-2 pb-4">
          <TabsTrigger value="information">Information adhérent</TabsTrigger>
          <TabsTrigger value="payment-method">Mode de payement</TabsTrigger>
          <TabsTrigger value="qr-code">Diffusion application</TabsTrigger>
        </TabsList>
        <TabsContent value="information">
          <Overview />
        </TabsContent>
        <TabsContent value="payment-method"></TabsContent>
        <TabsContent value="qr-code">
          <ShareQRCode />
        </TabsContent>
      </Tabs>
    </div>
  );
}
