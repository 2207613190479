const ENV = import.meta.env;

export default {
  APP_VERSION: ENV.VITE_VERSION,
  APP_NAME: ENV.VITE_APP_NAME,
  ENV: ENV.VITE_ENV,
  COOKIE_NAME: ENV.VITE_COOKIE_NAME ?? "ATOUTRAVAUX",
  API_URL: ENV.VITE_API_URI,
  ONLY_URI: ENV.VITE_ONLY_URI,
  ONLY_ESPACE_PERSO: ENV.VITE_ONLY_ESPACE_PERSO,
  BASE_IMAGE_URL: ENV.VITE_BASE_IMAGE_URL,
  STRIPE_API_KEY: ENV.VITE_STRIPE_API_KEY,
  SECRET_KEY: ENV.VITE_SECRET_KEY,
  PUSHER_APP_ID: ENV.VITE_PUSHER_APP_ID,
  PUSHER_APP_KEY: ENV.VITE_PUSHER_APP_KEY,
  PUSHER_APP_SECRET: ENV.VITE_PUSHER_APP_SECRET,
  PUSHER_APP_CLUSTER: ENV.VITE_PUSHER_APP_CLUSTER,
};
