import env from "@/config/env";
import Cookies, { CookieAttributes } from "js-cookie";

type CookieJSON = {
  email: string;
  firstname: string;
  lastname: string;
  token: string;
  role: string;
  user_uid: string;
  lang: string;
};

class CookiesService {
  private cookieOptions: CookieAttributes = {};
  private cookieName = env.COOKIE_NAME;

  constructor() {
    this.cookieOptions = {
      expires: 1,
      secure: true,
      sameSite: "Strict",
    };
  }

  getAll(): CookieJSON {
    const data = localStorage.getItem(this.cookieName) ?? "{}";
    return JSON.parse(data);
  }

  get(key: keyof CookieJSON) {
    const data = this.getAll() ?? {};
    return data[key];
  }

  set(input: Partial<CookieJSON> = {}) {
    const oldCookies = this.getAll();
    const updated = {
      ...oldCookies,
      ...input,
    };
    localStorage.setItem(this.cookieName, JSON.stringify(updated));
  }

  delete(keys: Partial<keyof CookieJSON>[] = []) {
    const cookies = this.getAll();
    for (const key of keys) {
      delete cookies[key];
    }
    localStorage.setItem(this.cookieName, JSON.stringify(cookies));
  }

  flush() {
    this.delete(["email", "firstname", "lastname", "role", "user_uid", "lang"]);
  }

  clearOldCookies() {
    Cookies.remove(this.cookieName);
  }
}

export default new CookiesService();
