import { Button } from "@/components/ui/button";
import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { typeProfile } from "@/contants/type-profile";
import { TypeProfile } from "../../types";

export const ProfileType = () => {
  let params = useParams();
  const navigate = useNavigate();
  const [type, setType] = useState<TypeProfile>(null);

  const handleGoTo = (type) => {
    navigate(`/avantages-pro${type}`);
  };

  useEffect(() => {
    if (params?.type) {
      const data = typeProfile.find((item) => item.slug === params?.type);
      setType(data);
    }
  }, [params?.type]);

  return (
    <div className="space-y-8">
      <h3 className="font-semi-bold">{type?.name}</h3>
      <div className="mt-4 ">
        <div className="flex gap-10">
          <img
            src="/image/profile.jpg"
            alt="logo"
            width={360}
            height={260}
            className="rounded-2xl"
          />
          <div dangerouslySetInnerHTML={{ __html: type?.description }} />
        </div>
      </div>
      <div className="flex gap-2 flex-row">
        <Button
          className="btn-green"
          onClick={() => handleGoTo(type?.previous)}
        >
          Précédent
        </Button>
        <Button className="btn-green" onClick={() => handleGoTo(type?.next)}>
          Suivant
        </Button>
      </div>
    </div>
  );
};
