import env from "@/config/env";
import { CookiesService } from "@/services";
import { create } from "zustand";

export interface IUserProfile {
  email: string;
  firstname: string;
  lastname: string;
  uid: string;
}

export interface IUserSessionStore {
  isLoggedIn: boolean;
  lang: string;
  token: string;
  role: string;
  profile: Partial<IUserProfile>;
  isConnected: boolean;
}

export interface IUserSessionSetter {
  setIsLoggedIn: (value: boolean) => void;
  updateState: (value: Partial<IUserSessionStore>) => void;
  updateProfile: (value: Partial<IUserProfile>) => void;
  flush: () => void;
}

const {
  token,
  refreshToken,
  lang,
  email,
  firstname,
  lastname,
  user_uid,
  role,
} = JSON.parse(localStorage.getItem(env.COOKIE_NAME) ?? "{}");

const initalState = {
  isLoggedIn: !!token,
  token,
  lang,
  error: "",
  loading: false,
  profile: {
    email,
    firstname,
    lastname,
    uid: user_uid,
  },
  isConnected: true,
  role,
};

const UserSessionStore = create<IUserSessionStore & IUserSessionSetter>(
  (set) => ({
    ...initalState,
    setIsLoggedIn: (value: boolean) => set({ isLoggedIn: value }),
    updateState: (newValue: any = {}) => set(() => ({ ...newValue })),
    updateProfile: (newValue: any = {}) =>
      set((state) => {
        const updated: any = { profile: { ...state.profile, ...newValue } };
        CookiesService.set({
          email: updated.profile.email,
          firstname: updated.profile.firstname,
          lastname: updated.profile.lastname,
          user_uid: updated.profile.uid,
        });
        return updated;
      }),
    flush: () =>
      set({
        isLoggedIn: false,
        profile: {},
        token: "",
        lang: "",
        role: "",
      }),
  }),
);

export default UserSessionStore;
