import { Button } from "@/components/ui/button";
import dayjs from "dayjs";

export const BlocItemsDossier = ({ dossier }) => {
  return (
    <div className="border-2 border-green-light flex flex-row rounded-xl overflow-hidden">
      <div className="bg-blue-400 w-1/4 flex items-center flex-col gap-2   justify-center">
        <p className="text-center px-2 w-full">Dossier</p>
        <div className="flex flex-col items-center">
          <img src={`/image/icon/${dossier.slug}.png`} width={50} height={50} alt="logo" />
          <p className="font-semibold text-lg group-hover:font-bold text-left">{dossier.title}</p>
        </div>
      </div>
      <div className="flex flex-row gap-4 justify-between p-4 bg-white w-full">
        <div className="m-auto">
          <img className="w-[150px] h-[150px] object-cover rounded-full" src="/image/profile.jpg" alt="logo" />
        </div>
        <div className="space-y-4">
          <div className="space-y-2 text-green-light">
            <p className="font-bold">EN ATTENTE</p>
            <p>Dossier entreprise N</p>
            <p>Nom: Roseline</p>
            <p>{dayjs().format("DD-MM-YYYY")}</p>
          </div>
          <Button className="btn-green">Consulter</Button>
        </div>
      </div>
    </div>
  );
};
