import { listDossierContact } from "@/modules/dossiers/data";
import { BlocTypeDossier } from "../../bloc-type-dossier";

export const StepOne = ({ handleSelectDossier }) => {
  return (
    <div className="space-y-8 w-3/4">
      <h1 className="text-3xl font-bold text-center">Choisissez une domaine</h1>
      <div className={`grid grid-cols-4 gap-6`}>
        {listDossierContact.map((dossier, index) => (
          <BlocTypeDossier key={index} dossier={dossier} setDossier={handleSelectDossier} className="midle-bloc" />
        ))}
      </div>
    </div>
  );
};
