import { Button } from "@/components/ui/button";
import { useState } from "react";
import { InfoGift } from "../info-gift";

export const InfoPub = () => {
  const [showInfo, setShowInfo] = useState(false);

  return (
    <div className="container lg:px-0 px-8 mx-auto mt-16">
      {!showInfo ? (
        <>
          <img src="/image/pub.png" alt="logo" className="rounded-2xl absolute left-0 bottom-0" />
          <div className="flex flex-row gap-8 justify-end">
            <div className="flex items-end flex-col gap-12">
              <div className="space-y-4">
                <h1 className="font-bold text-right">UN BON PLAN, CA SE PARTAGE !</h1>
                <div>
                  <p className="text-right">Offrez une application 100% gratuite pour vous et vos clients</p>
                  <p className="text-right">Aidez les à passer le cap dans leurs projets de travaux</p>
                </div>
              </div>
              <div className="rounded-2xl bg-gradient-to-r from-[#3085D6] to-[#004AAD] flex gap-12 px-8 py-8 w-3/5 ">
                <div className="flex flex-row gap-4 w-1/2">
                  <div className="space-y-4">
                    <p className="font-semibold text-lg ">
                      Transmettez facilement l’application gratuitement à vos clients avec votre QR code
                    </p>
                  </div>
                  <img src="/image/qr-phone.png" alt="logo" />
                </div>
                <div className="flex flex-row gap-4 w-1/2">
                  <div className="space-y-4 flex flex-col justify-between">
                    <p className="font-semibold text-lg">Valorisez votre cadeau en apposant votre publicité dans l’appli !</p>
                    <Button className="btn-green" onClick={() => setShowInfo(true)}>
                      Voir condition
                    </Button>
                  </div>
                  <img src="/image/pub-phone.png" alt="logo" />
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <InfoGift />
      )}
    </div>
  );
};
