import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { UploadButtonFile } from "@/modules/media/components/upload-file";

export const AddDevis = () => {
  return (
    <div className="space-y-8 w-3/4">
      <div className="flex flex-row gap-4 items-center w-full justify-between">
        <div className="space-y-4 w-full">
          <div className="flex flex-row justify-between items-center w-full">
            <h1 className="text-3xl font-bold">Devis</h1>
            <div>
              <UploadButtonFile title={"Ajouter un devis"} inputChangeHandler={() => {}} inputRef={null} />
            </div>
          </div>
          <hr className="border-green-light"></hr>
          <div className="flex flex-row gap-8 items-center">
            <p>Devis Numero 1</p>
            <Select>
              <SelectTrigger className="w-[160px]">
                <SelectValue placeholder="Status du devis" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="waiting">En attente</SelectItem>
                <SelectItem value="validated">Valider</SelectItem>
                <SelectItem value="not_validated">Réfuser</SelectItem>
              </SelectContent>
            </Select>
          </div>
        </div>
      </div>
    </div>
  );
};
